var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-container", [
        _c(
          "div",
          [
            _c(
              "v-breadcrumbs",
              {
                staticClass: "pl-0 pb-1",
                class: _vm.isLightTheme ? "breadcrumb-item" : "",
                attrs: { items: _vm.breadcrumbs }
              },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "breadcrumbs-chevro",
                    attrs: { slot: "divider" },
                    slot: "divider"
                  },
                  [_vm._v("fas fa-chevron-right")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "section-title font-weight-bold primary_font--text"
              },
              [_vm._v(_vm._s(_vm.$t("views.Faq.title")))]
            )
          ],
          1
        )
      ]),
      _c("v-container", { staticClass: "py-1" }, [
        _c(
          "div",
          [
            _c(
              "v-expansion-panels",
              { attrs: { flat: "" } },
              _vm._l(_vm.filteredItems, function(item, index) {
                return _c(
                  "v-expansion-panel",
                  { key: index, staticClass: "background mb-3" },
                  [
                    _c("v-expansion-panel-header", {
                      staticClass:
                        "custom-expansion-painel white d-flex flex-row-reverse",
                      attrs: { "hide-actions": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var open = ref.open
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "faq-content body-1 primary_font--text font-weight-medium ml-7 text--start"
                                  },
                                  [_vm._v(_vm._s(item.question))]
                                ),
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "24",
                                            color: "primary"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                open ? "mdi-minus" : "mdi-plus"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("v-expansion-panel-content", { staticClass: "white" }, [
                      _c(
                        "span",
                        { staticClass: "body-2 secondary_font--text" },
                        [_vm._v(_vm._s(item.answer))]
                      )
                    ])
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }