<template>
    <div>
       <v-container>
           <div>
                <v-breadcrumbs :class="isLightTheme ? 'breadcrumb-item': ''" class="pl-0 pb-1" :items="breadcrumbs">
                   <v-icon slot="divider" class="breadcrumbs-chevro">fas fa-chevron-right</v-icon>
                </v-breadcrumbs>

                <div class="section-title font-weight-bold primary_font--text">{{ $t('views.Faq.title') }}</div>
           </div>
        </v-container>

        <v-container class="py-1">
            <div>
                <v-expansion-panels flat>
                    <v-expansion-panel
                        v-for="(item, index) in filteredItems" :key="index"
                        class="background mb-3"
                    >
                        <v-expansion-panel-header 
                            class="custom-expansion-painel white d-flex flex-row-reverse"
                            hide-actions 
                            v-slot:default="{ open }"
                         >
                            <span class="faq-content body-1 primary_font--text font-weight-medium ml-7 text--start">{{ item.question }}</span>
                            <template>
                                <div>
                                    <v-icon size="24" color="primary">
                                        {{open?'mdi-minus':'mdi-plus'}}
                                    </v-icon>
                                </div>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="white">
                            <span class="body-2 secondary_font--text">{{ item.answer }}</span> 
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-container>
    </div>
</template>

<script>
import RateColor from '@/shared/mixins/RateColor'
import { mapGetters } from 'vuex'

export default {
    name: 'Faq',
    mixins: [ RateColor ],
    data: vm => {
        return {
             breadcrumbs: [
                { text: vm.$t('drawer.home'), to: { name: 'home' } },
                { text: vm.$t('drawer.faq'), disabled: true    },
            ],
            tab: null,
        }
    },
    created() {
        if(!this.filteredItems.length) 
            this.$router.push({ name: 'home' })
    },
    computed: {
        ...mapGetters({
            faq: 'company/faq',
        }),
        filteredItems() {
            return Array.isArray(this.faq.items) ? this.faq.items
                .filter(item => !this.$lodash.isEmpty(item)) : []
        },
    }
}
</script>

<style lang="scss">
.custom-expansion-painel {
    height: 76px !important;
}

.faq-content {
    width: 100% !important;
}
</style>