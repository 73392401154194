import { render, staticRenderFns } from "./FaqView.vue?vue&type=template&id=61bdd798&"
import script from "./FaqView.vue?vue&type=script&lang=js&"
export * from "./FaqView.vue?vue&type=script&lang=js&"
import style0 from "./FaqView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBreadcrumbs,VContainer,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-customer/cplug-fid-front-customer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61bdd798')) {
      api.createRecord('61bdd798', component.options)
    } else {
      api.reload('61bdd798', component.options)
    }
    module.hot.accept("./FaqView.vue?vue&type=template&id=61bdd798&", function () {
      api.rerender('61bdd798', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/faq/FaqView.vue"
export default component.exports